import React from "react";
import imgA1 from "../../assets/image/thumbnails/1.jpg";
import imgA2 from "../../assets/image/thumbnails/2.jpg";
import imgA3 from "../../assets/image/thumbnails/3.jpg";
import imgA4 from "../../assets/image/thumbnails/4.jpg";

const Affiliates = () => {
  return (
    <>
      {/* <!-- Pricing Area -->  */}
      <div id="affiliates" className="pricing-area">
        <div className="container pt-12 pt-lg-24 pb-13 pb-lg-25">
          <div className="row justify-content-center">
            <div
              className="col-xxl-10 col-lg-10 col-md-10 "
              data-aos="fade-in"
              data-aos-duration="1000"
            >
              {/* <!-- section-title start --> */}
              <div className="section-title text-center mb-12 mb-lg-12 pb-lg-15 pb-0">
                <h2 className="mb-9">
                  Partners
                </h2>
                <p className="text-default-color text-left font-size-4 px-5 ">
                </p>
              </div>
              {/* <!-- section-title end --> */}
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-xxl-10 col-xl-11">
              <div className="row justify-content-center">
                <div
                  className="col-lg-3 col-md-6 col-xs-9 "
                  data-aos="fade-right"
                  data-aos-duration="1000"
                >
                  {/* <!-- card start --> */}
                  <div className="card border-0 rounded-8 hover-shadow-hitgray">
                    {/* <!-- card-header start --> */}
                    <div className="bg-transparent border-hit-gray-opacity-5 text-center pb-8" style={{ minHeight: "370px" }}>
                      <img src={imgA1} className="card-img-top" style={{ backgroundColor: "#06213e" }} alt="..." />
                      <div className="pricing-title text-center">
                        <h6 className="font-weight-semibold font-size-5 text-black-2 pt-8">
                          Global Organizations
                        </h6>
                        <h2 className="mt-8 text-dodger">
                          <span className="text-left pl-8 pr-8 font-size-4 text-smoke font-weight-normal">
                            VocalGlocal envisages collaboration with world leading organizations focused on global trade, IP protection  and support for produce of indigenous and local communities for international market access like WIPO, OriGIN(Organization for an International Geographical Indications Network,  Sustainable Development Group- UN etc.
                          </span>{" "}
                        </h2>
                      </div>
                    </div>
                  </div>
                  {/* <!-- card end --> */}
                </div>
                <div
                  className="col-lg-3 col-md-6 col-xs-9 "
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  {/* <!-- card start --> */}
                  <div className="card border-0 rounded-8 hover-shadow-hitgray mb-8">
                    {/* <!-- card-header start --> */}
                    <div className="bg-transparent border-hit-gray-opacity-5 text-center pb-8" style={{ minHeight: "370px" }}>
                      <img src={imgA2} className="card-img-top" style={{ backgroundColor: "#06215e" }} alt="..." />
                      <div className="pricing-title text-center">
                        <h6 className="font-weight-semibold font-size-5 text-black-2 pt-8">
                        Government and industry associations
                        </h6>
                        <h2 className="mt-8 text-dodger">
                          <span className="text-left pl-8 pr-8 font-size-4 text-smoke font-weight-normal">
                          VocalGlocal relationships with chambers of commerce, export promotion councils, Manufacture and trade associations across countries help local producers greater access and market reach.
                          </span>{" "}
                        </h2>
                      </div>
                    </div>
                  </div>
                  {/* <!-- card end --> */}
                </div>
                <div
                  className="col-lg-3 col-md-6 col-xs-9 "
                  data-aos="fade-left"
                  data-aos-duration="1000"
                >
                  {/* <!-- card start --> */}
                  <div className="card border-0 rounded-8 hover-shadow-hitgray mb-8">
                    {/* <!-- card-header start --> */}
                    <div className="bg-transparent border-hit-gray-opacity-5 text-center pb-8" style={{ minHeight: "200px" }}>
                      <img src={imgA3} className="card-img-top" style={{ backgroundColor: "#06215e" }} alt="..." />
                      <div className="pricing-title text-center">
                        <h6 className="font-weight-semibold font-size-5 text-black-2 pt-8">
                        Producers and manufacturers
                        </h6>
                      </div>
                      <h2 className="mt-8 text-dodger">
                        <span className="text-left pl-8 pr-8 font-size-4 text-smoke font-weight-normal">
                        We welcome local producers and manufacturers of best local products and GI tagged product vendors to sign up using the vendor partner form above.
                        </span>{" "}
                      </h2>
                    </div>
                  </div>
                  {/* <!-- card end --> */}
                </div>
                <div
                  className="col-lg-3 col-md-6 col-xs-9 "
                  data-aos="fade-left"
                  data-aos-duration="1000"
                >
                  {/* <!-- card start --> */}
                  <div className="card border-0 rounded-8 hover-shadow-hitgray mb-8">
                    {/* <!-- card-header start --> */}
                    <div className="bg-transparent border-hit-gray-opacity-5 text-center pb-8" style={{ minHeight: "200px" }}>
                      <img src={imgA4} className="card-img-top" style={{ backgroundColor: "#06215e" }} alt="..." />
                      <div className="pricing-title text-center">
                        <h6 className="font-weight-semibold font-size-5 text-black-2 pt-8">
                        Exhibitions, Fairs and Events Partners
                        </h6>
                      </div>
                      <h2 className="mt-8 text-dodger">
                        <span className="text-left pl-8 pr-8 font-size-4 text-smoke font-weight-normal">
                        VocalGlocal will represent producers, represent GI tag product associations in all world major exhibitions and fairs. VocalGlocal 24/7 year long Virtual fair will focus on product categories and country special fairs.
                        </span>{" "}
                      </h2>
                    </div>
                  </div>
                  {/* <!-- card end --> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Affiliates;
