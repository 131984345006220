import React from "react";

import img1 from "../../assets/image/thumbnails/ag.jpg";
import img2 from "../../assets/image/thumbnails/bv.jpg";
import img3 from "../../assets/image/thumbnails/fs.jpg";
import img4 from "../../assets/image/thumbnails/hc.jpg";
import img5 from "../../assets/image/thumbnails/mg.jpg";
import img6 from "../../assets/image/thumbnails/ng.jpg";


const Industries = () => {

  return (
    <>
      {/* <!-- FeaturedProviders Area -->  */}
      <section id="industries" className="bg-athens pt-12 pt-lg-24 pb-7 pb-lg-25">
        <div className="container">
          {/* <!-- Section Title --> */}
          <div className="row justify-content-center mb-lg-16 mb-11">
            <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-12 text-center">
              <h2 className="mb-6 mb-lg-7 text-black-2 font-size-10">
                Industries
              </h2>
              <p className="px-xs-3 px-md-12 px-lg-8 px-xl-8 px-xxl-6 text-center font-size-4 mb-0">
                We offer Best of World Products across Categories of Geographical indications
              </p>
            </div>
          </div>
          {/* <!-- Section Title End --> */}
          <div className="row justify-content-center">
            <div className="col-md-10 text-black-2">
              <div className="row">
                <div className="mt-8 mb-8" style={{ float: "left", display: "flex", alignItems:"center" }}>
                  <img width="25%"
                    src={img1}
                    className="align-self-center mr-3 mt-2"
                    alt=""
                  />
                  <div style={{display:"relative"}}>
                    <div className="font-weight-bold font-size-5">Agricultural Products</div>
                    <div className="text-smoke">We offer best of agricultural products produced by local communities with centuries of tradition and perfection of botanical purity. A wide variety tea, coffee, fruits, rice and millets, cereals and grains unknown and unseen by many in the world.  </div>
                  </div>
                </div>
                <div className="mt-8 mb-8" style={{ float: "left", display: "flex", alignItems:"center" }}>
                  <img width="25%"
                    src={img2}
                    className="align-self-center mr-3 mt-2"
                    alt=""
                  />
                  <div style={{display:"relative"}}>
                    <div className="font-weight-bold font-size-5">Beverages</div>
                    <div className="text-smoke">Get the world’s best beverages brewed by local communities in communion with nature and pleasure. Irish whiskies, Japanese Sake, Lithuanian Stakliškės beer and other non alcoholic beverages. </div>
                  </div>
                </div>
                <div className="mt-8 mb-8" style={{ float: "left", display: "flex", alignItems:"center" }}>
                  <img width="25%"
                    src={img3}
                    className="align-self-center mr-3 mt-2"
                    alt=""
                  />
                  <div style={{display:"relative"}}>
                    <div className="font-weight-bold font-size-5">Foodstuff</div>
                    <div className="text-smoke">Experience and enjoy Exotic groceries, finest culinary additives , and GI tagged foodstuff ranging from spices to herbs, Ajvar Paprika from Serbia to Munder Safran from Switzerland, Papantia Vanilla from Mexico. </div>
                  </div>
                </div>
                <div className="mt-8 mb-8" style={{ float: "left", display: "flex", alignItems:"center" }}>
                  <img width="25%"
                    src={img4}
                    className="align-self-center mr-3 mt-2"
                    alt=""
                  />
                  <div style={{display:"relative"}}>
                    <div className="font-weight-bold font-size-5">Handicrafts</div>
                    <div className="text-smoke">A glass pitcher, a wicker basket, a tunic of coarse cloth. Their beauty is inseparable from their function. Handicrafts belong to a world existing before the separation of the useful and the beautiful. Experience the best handicrafts of the world thru VocalGlocal. </div>
                  </div>
                </div>
                <div className="mt-8 mb-8" style={{ float: "left", display: "flex", alignItems:"center" }}>
                  <img width="25%"
                    src={img5}
                    className="align-self-center mr-3 mt-2"
                    alt=""
                  />
                  <div style={{display:"relative"}}>
                    <div className="font-weight-bold font-size-5">Manufactured Goods</div>
                    <div className="text-smoke">Acquire the best of GI tagged manufactured items from ceramics, to silks, metal boxes to meenakaari jewelboxes from vocalglocal. </div>
                  </div>
                </div>
                <div className="mt-8" style={{ float: "left", display: "flex", alignItems:"center" }}>
                  <img width="25%"
                    src={img6}
                    className="align-self-center mr-3 mt-2"
                    alt=""
                  />
                  <div style={{display:"relative"}}>
                    <div className="font-weight-bold font-size-5">Natural Goods</div>
                    <div className="text-smoke">Vocalglocal provides experience and easy ownership of exclusive minerals, stones and raw material souvenirs that make ownership of world best natural goods a blessed possession. </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Industries;
