import React from "react";

const Offerings = () => {

    return (
        <>
            {/* <!-- FeaturedProviders Area -->  */}
            <section id="offerings" className="bg-athens pt-12 pt-lg-24 pb-7 pb-lg-25">
                <div className="container">
                    {/* <!-- Section Title --> */}
                    <div className="row justify-content-center mb-lg-16 mb-11">
                        <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-10 text-center">
                            <h2 className="mb-6 mb-lg-7 text-black-2 font-size-10">
                                Offerings
                            </h2>
                            <p className="px-xs-3 px-md-12 px-lg-8 px-xl-8 px-xxl-6 text-left font-size-4 mb-0">
                                <strong>VOCALGLOCAL</strong> offers ...
                            </p>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-11 font-size-6 text-black-2">
                            <div className="row">
                                <div className="col-md-6 mb-12" style={{ minHeight: "80px", alignItems: "center", borderLeft: "4px #f59930 solid" }}>
                                    <span>Best Local products of the world</span>
                                    <ul className="text-default-color" style={{ fontSize: "16px" }}>
                                        <li>GI Tagged Products of the world</li>
                                        <li>Pending GI tag products</li>
                                        <li>Handmade and local produce of regional communities and minority groups</li>
                                        <li>Traditional arts and crafts</li>
                                        <li>Souvenirs of nations presented to head of states and VIPs</li>
                                    </ul>
                                </div>
                                <div className="col-md-6 mb-12" style={{ minHeight: "80px", alignItems: "center", borderLeft: "4px #f59930 solid" }}>
                                    <span>Global Awareness Creation</span>
                                    <ul className="text-default-color" style={{ fontSize: "16px" }}>
                                        <li>Campaigns on value of Vocalglocal</li>
                                        <li>GI Tag products publicity</li>
                                        <li>Presence on Travel and international airlines portals</li>
                                        <li>VocalGlocal as SDG goals of UN</li>
                                        <li>Participation in international fairs</li>
                                    </ul>
                                </div>
                                <div className="col-md-6 mb-12" style={{ minHeight: "80px", alignItems: "center", borderLeft: "4px #f59930 solid" }}>
                                    <span>Digital Platform P2C</span>
                                    <ul className="text-default-color" style={{ fontSize: "16px" }}>
                                        <li>Producers to Consumers connect portal</li>
                                        <li>Country chambers of commerce and export promotion council links</li>
                                        <li>Consignment sales warehouses-dedicated stocking points and SKUs</li>
                                        <li>Payment Gateways</li>
                                        <li>Order tracking</li>
                                        <li>Reasonable return policy and logistics</li>
                                    </ul>
                                </div>
                                <div className="col-md-6 mb-12" style={{ minHeight: "80px", alignItems: "center", borderLeft: "4px #f59930 solid" }}>
                                    <span>Physical Logistics infrastructure</span>
                                    <ul className="text-default-color" style={{ fontSize: "16px" }}>
                                        <li>Agreements with producers as partners</li>
                                        <li>Agreements with country chambers of commerce
                                            and export promotion councils for shares storage
                                            space and consignment stocks</li>
                                        <li>Country stocking points</li>
                                        <li>Regional fulfillment hubs</li>
                                        <li>Tie ups with logistics vendors</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Offerings;
