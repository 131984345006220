import React from "react";
import { Button } from "react-bootstrap";

import { Select } from "../../components/Core";
import imgP from "../../assets/image/globe-5.png";
import "./Hero.css"

const defaultCountries = [
  { value: "au", label: "Australia" },
  { value: "de", label: "Germany" },
  { value: "in", label: "India" },
  { value: "il", label: "Israel" },
  { value: "kr", label: "South Korea" },
  { value: "ua", label: "Ukraine" },
  { value: "us", label: "USA" },
];

const Hero = () => {
  return (
    <>
    <div id="home">
        {/* <!-- Hero Area --> */}
        <div id="hero"
          className="position-relative z-index-1 pt-26 dark-mode-texts"
        >
          {/*<div className="pos-abs-tr h-100" style={{top:"120px", right:"5%"}}>
            <img src={imgP} alt="" style={{animation:"moveleftbounce 5s linear infinite"}}/>
          </div>*/}
          <div className="pos-abs-tr h-100" style={{ top: "100px", right: "2%" }}>
            <img width="100%" src={imgP} alt="" />
          </div>
          <div className="container position-static">
            <div style={{ textAlign: "center", color: "white", fontSize: '28px' }}><b>Marketplace for Best of World Local Products</b></div>
            <div style={{ textAlign: "center", color: "white", fontSize: '20px' }}>
              Enable global business for the best local products of the world
            </div>
            <div className="row position-relative align-items-center position-static">
              <div
                className="col-xxl-7 col-xl-8 col-lg-9 pt-lg-18 pb-lg-33 pb-md-28 pb-xs-26 pb-29 pt-md-20"
                data-aos="fade-right"
                data-aos-duration="800"
                data-aos-delay="500"
              >
                <div className="row">
                  <div className="col-xxl-8 col-xl-8 col-md-8 col-sm-10" style={{ color: "white", backgroundColor: "rgba(255, 255, 255, 0.0)" }}>
                    <div className="font-size-4 font-weight-semibold mb-7" style={{textTransform:"uppercase"}}>
                      Build global awareness on best local products of the world
                    </div>
                    <div className="font-size-4 font-weight-semibold mb-7 mt-7" style={{textTransform:"uppercase"}}>
                      Enable consumption experience for the world populace
                    </div>
                    <div className="font-size-4 font-weight-semibold mb-7" style={{textTransform:"uppercase"}}>
                      Create digital platform for the best local products of the world
                    </div>
                    <div className="font-size-4 font-weight-semibold mb-7" style={{textTransform:"uppercase"}}>
                      Reinforce visibility thru global exhibitions and fairs
                    </div>
                    <div className="font-size-4 font-weight-semibold mb-7" style={{textTransform:"uppercase"}}>
                      Producers and consumers - P2C direct connect
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- Hero Form --> */}
              {/*
              <div className="col-lg-11 col-12 translateY-50 pos-abs-bl">
                <form
                  action="/"
                  className="search-form"
                  data-aos="fade-up"
                  data-aos-duration="800"
                  data-aos-delay="500"
                >
                  <div className="filter-search-form-2 bg-white rounded-sm shadow-7 pr-8 py-7 pl-6">
                    <div className="filter-inputs">
                      <div className="form-group position-relative">
                        <input
                          className="form-control focus-reset pl-13"
                          type="text"
                          id="keyword"
                          placeholder="Type keywords"
                        />
                        <span className="h-100 w-px-50 pos-abs-tl d-flex align-items-center justify-content-center font-size-6">
                          <i className="icon icon-zoom-2 text-primary font-weight-bold"></i>
                        </span>
                      </div>
                      <div className="form-group position-relative">
                        <Select
                          options={defaultCountries}
                          className="pl-8 h-100 arrow-3 font-size-4 d-flex align-items-center w-100"
                          border={false}
                        />

                        <span className="h-100 w-px-50 pos-abs-tl d-flex align-items-center justify-content-center font-size-6">
                          <i className="icon icon-pin-3 text-primary font-weight-bold"></i>
                        </span>
                      </div>
                    </div>
                    <div className="button-block">
                      <Button
                        type="submit"
                        className="line-height-reset h-100 btn-submit w-100 text-uppercase"
                      >
                        Search
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
              */}
              {/* <!-- End Hero Form --> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
